import * as React from 'react'
import { Link } from 'gatsby'
import { PrismicProvider } from '@prismicio/react'

import "@popperjs/core/dist/umd/popper.min"

import "bootstrap/dist/js/bootstrap.min"
import './src/styles/main.scss'

export const wrapRootElement = ({ element }) => (
  <PrismicProvider
    internalLinkComponent={({ href, ...props }) => (
      <Link to={href + "/"} {...props} />
    )}
  >
      {element}
  </PrismicProvider>
)
